(function() {
"use strict"
// =========================================================

window.lazyLoadAsyncRun = function({bundleId, bundleSrc, asyncClosure, isModule, alwaysReload}) {
    let script = document.getElementById(bundleId)
	if (script && alwaysReload) {
		script.parentElement.removeChild(script)
		script = null
	}
    return (script ? asyncClosure() :
        new Promise((resolve, reject) => {
            try {
                const fnOnLoad = function() {
                    asyncClosure()
                        .then(ret => resolve(ret))
                        .catch(err => reject(err))
                }
                const script = document.createElement('script')
                script.id = bundleId
                if (!isModule) {
                    script.src = bundleSrc
                    script.onload = fnOnLoad
                } else {
                    const nameOfFnOnLoad = 'onLoad' + bundleId
                    script.type = 'module'
                    script.innerText = bundleSrc + ';window.' + nameOfFnOnLoad + '()'
                    window[nameOfFnOnLoad] = fnOnLoad
                }

                document.head.appendChild(script)
            } catch (err) {
                reject(err)
            }
        })
    )
}

// =========================================================
})()
